import { makeModificationSummary } from '../../../core';
import { makeEntity } from '../../../utils';
import { CustomSectorCompanyDto } from '../types/CustomSectorCompany';
import { makeCustomSector } from './CustomSector.mock';
import { makeCustomSubSector } from './CustomSubSector.mock';

export const makeCustomSectorCompany = makeEntity<CustomSectorCompanyDto>({
  companyId: 'mock-company-id',
  customSector: makeCustomSector(),
  customSubSector: makeCustomSubSector(),
  lastModified: makeModificationSummary(),
});
