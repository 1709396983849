import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { BrokerPlDownloadQuery, BrokerPlTableDto, BrokerPlTableQuery } from './types/BrokerPlTable';

export const fetchPnlLeagueTable = makePostRequest<BrokerPlTableQuery, BrokerPlTableDto>(
  apiClient,
  () => `/dataLabs/pnlLeagueTable/table`
);
export type FetchPnlLeagueTableResponse = UnpackPromise<ReturnType<typeof fetchPnlLeagueTable>>;

export const downloadPnlLeagueTable = makePostDownloadRequest<BrokerPlDownloadQuery>(
  apiClient,
  () => `/dataLabs/pnlLeagueTable/download`
);
export type DownloadPnlLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadPnlLeagueTable>
>;
