import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  FundIoiParticipationDownloadQuery,
  FundIoiParticipationTableDto,
  FundIoiParticipationTableQuery,
} from './types/FundIoiParticipationTable';

export const fetchFundIoiParticipationTable = makePostRequest<
  FundIoiParticipationTableQuery,
  FundIoiParticipationTableDto
>(apiClient, () => '/dataLabs/fundIoiParticipation/table');
export type FetchFundIoiParticipationTableResponse = UnpackPromise<
  ReturnType<typeof fetchFundIoiParticipationTable>
>;

export const downloadFundIoiParticipationTable =
  makePostDownloadRequest<FundIoiParticipationDownloadQuery>(
    apiClient,
    () => '/dataLabs/fundIoiParticipation/download'
  );
export type DownloadFundIoiParticipationTableResponse = UnpackPromise<
  ReturnType<typeof downloadFundIoiParticipationTable>
>;
