import { UUID } from '../../core';
import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { CustomSectorDto } from './types/CustomSector';
import { CustomSectorBaseDto } from './types/CustomSectorBase';
import { CustomSubSectorDto } from './types/CustomSubSector';

export const fetchCustomSectors = makeGetRequest<CustomSectorDto[]>(
  apiClient,
  () => '/customSectors'
);
export type FetchCustomSectorsResponse = UnpackPromise<ReturnType<typeof fetchCustomSectors>>;

export const createCustomSector = makePostRequest<CustomSectorBaseDto, CustomSectorDto>(
  apiClient,
  () => '/customSectors'
);
export type CreateCustomSectorResponse = UnpackPromise<ReturnType<typeof createCustomSector>>;

export const createCustomSubSector = makePostRequest<
  CustomSectorBaseDto,
  CustomSubSectorDto,
  { parentId: UUID }
>(apiClient, ({ parentId }) => `/customSectors/${parentId}/customSubSectors`);

export type CreateCustomSubSectorResponse = UnpackPromise<ReturnType<typeof createCustomSubSector>>;

export const updateCustomSector = makePutRequest<
  CustomSectorBaseDto,
  CustomSectorDto,
  { id: UUID }
>(apiClient, ({ id }) => `/customSectors/${id}`);

export type UpdateCustomSectorResponse = UnpackPromise<ReturnType<typeof updateCustomSector>>;

export const updateCustomSubSector = makePutRequest<
  CustomSectorBaseDto,
  CustomSubSectorDto,
  { id: UUID; parentId: UUID }
>(apiClient, ({ id, parentId }) => `/customSectors/${parentId}/customSubSectors/${id}`);

export type UpdateCustomSubSectorResponse = UnpackPromise<ReturnType<typeof updateCustomSector>>;

export const deleteCustomSector = makeDeleteRequest<{ id: UUID }>(
  apiClient,
  ({ id }) => `/customSectors/${id}`
);

export type DeleteCustomSectorResponse = UnpackPromise<ReturnType<typeof deleteCustomSector>>;

export const deleteCustomSubSector = makeDeleteRequest<{ id: UUID; parentId: UUID }>(
  apiClient,
  ({ id, parentId }) => `/customSectors/${parentId}/customSubSectors/${id}`
);

export type DeleteCustomSubSectorResponse = UnpackPromise<ReturnType<typeof deleteCustomSubSector>>;
