import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  UnderwriterProfileChartsDto,
  UnderwriterProfileChartsQuery,
} from './types/UnderwriterPofileCharts';
import {
  UnderwriterProfileDownloadQuery,
  UnderwriterProfileTableDto,
  UnderwriterProfileTableQuery,
} from './types/UnderwriterProfileTable';

export const fetchUnderwriterProfileCharts = makePostRequest<
  UnderwriterProfileChartsQuery,
  UnderwriterProfileChartsDto
>(apiClient, () => '/dataLabs/underwriterProfile/chart');
export type FetchUnderwriterProfileChartsResponse = UnpackPromise<
  ReturnType<typeof fetchUnderwriterProfileCharts>
>;

export const fetchUnderwriterProfileTable = makePostRequest<
  UnderwriterProfileTableQuery,
  UnderwriterProfileTableDto
>(apiClient, () => '/dataLabs/underwriterProfile/table');
export type FetchUnderwriterProfileTableResponse = UnpackPromise<
  ReturnType<typeof fetchUnderwriterProfileTable>
>;

export const downloadUnderwriterProfile = makePostDownloadRequest<UnderwriterProfileDownloadQuery>(
  apiClient,
  () => '/dataLabs/underwriterProfile/download'
);
export type DownloadUnderwriterProfileResponse = UnpackPromise<
  ReturnType<typeof downloadUnderwriterProfile>
>;
