import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  AftermarketUnderwriterOfferingsDownloadQuery,
  AftermarketUnderwriterOfferingsTableDto,
  AftermarketUnderwriterOfferingsTableQuery,
} from './types/AftermarketUnderwriterOfferingsTable';

export const fetchAftermarketUnderwriterOfferingsTable = makePostRequest<
  AftermarketUnderwriterOfferingsTableQuery,
  AftermarketUnderwriterOfferingsTableDto
>(apiClient, () => '/dataLabs/afterMarketUnderwriterOfferings/table');
export type FetchAftermarketUnderwriterOfferingsTableResponse = UnpackPromise<
  ReturnType<typeof fetchAftermarketUnderwriterOfferingsTable>
>;

export const downloadAftermarketUnderwriterOfferings =
  makePostDownloadRequest<AftermarketUnderwriterOfferingsDownloadQuery>(
    apiClient,
    () => '/dataLabs/afterMarketUnderwriterOfferings/download'
  );
export type DownloadAftermarketUnderwriterOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadAftermarketUnderwriterOfferings>
>;
