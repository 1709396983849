import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  BrokerPlOfferingsDownloadQuery,
  BrokerPlOfferingsTableDto,
  BrokerPlOfferingsTableQuery,
} from './types/BrokerPlOfferingsTable';

export const fetchBrokerPlOfferingsTable = makePostRequest<
  BrokerPlOfferingsTableQuery,
  BrokerPlOfferingsTableDto
>(apiClient, () => '/dataLabs/brokerPnlOfferings/table');
export type FetchBrokerPlOfferingsTableResponse = UnpackPromise<
  ReturnType<typeof fetchBrokerPlOfferingsTable>
>;

export const downloadBrokerPlOfferings = makePostDownloadRequest<BrokerPlOfferingsDownloadQuery>(
  apiClient,
  () => '/dataLabs/brokerPnlOfferings/download'
);
export type DownloadBrokerPlOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadBrokerPlOfferings>
>;
