export enum TimeWindow {
  CUSTOM = 'CUSTOM',
  N7D = 'N7D',
  N14D = 'N14D',
  N30D = 'N30D',
  N90D = 'N90D',
  N180D = 'N180D',
  L7D = 'L7D',
  L14D = 'L14D',
  L30D = 'L30D',
  L90D = 'L90D',
  LTM = 'LTM',
  MTD = 'MTD',
  YTD = 'YTD',
  PM = 'PM',
  PQ = 'PQ',
  PYR = 'PYR', // "R" means Real, because PY without R means PY+LTD (historically)
  PY = 'PY',
  P2Y = 'P2Y',
  P3Y = 'P3Y',
  P4Y = 'P4Y',
}
