import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { IpoStructureChartsDto, IpoStructureChartsQuery } from './types/IpoStructureCharts';
import {
  IpoStructureDownloadQuery,
  IpoStructureTableDto,
  IpoStructureTableQuery,
} from './types/IpoStructureTable';

export const fetchIpoStructureCharts = makePostRequest<
  IpoStructureChartsQuery,
  IpoStructureChartsDto
>(apiClient, () => '/dataLabs/ipoStructure/chart');

export type FetchIpoStructureChartsResponse = UnpackPromise<
  ReturnType<typeof fetchIpoStructureCharts>
>;

export const fetchIpoStructureTable = makePostRequest<IpoStructureTableQuery, IpoStructureTableDto>(
  apiClient,
  () => '/dataLabs/ipoStructure/table'
);
export type FetchIpoStructureTableResponse = UnpackPromise<
  ReturnType<typeof fetchIpoStructureTable>
>;

export const downloadIpoStructure = makePostDownloadRequest<IpoStructureDownloadQuery>(
  apiClient,
  () => '/dataLabs/ipoStructure/download'
);
export type DownloadIpoStructureResponse = UnpackPromise<ReturnType<typeof downloadIpoStructure>>;
