import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  AftermarketMatrixDownloadQuery,
  AftermarketMatrixTableDto,
  AftermarketMatrixTableQuery,
} from './types/AftermarketMatrixTable';

export const fetchAftermarketMatrixTable = makePostRequest<
  AftermarketMatrixTableQuery,
  AftermarketMatrixTableDto
>(apiClient, () => '/dataLabs/afterMarketMatrix/table');
export type FetchAftermarketMatrixTableResponse = UnpackPromise<
  ReturnType<typeof fetchAftermarketMatrixTable>
>;

export const downloadAftermarketMatrix = makePostDownloadRequest<AftermarketMatrixDownloadQuery>(
  apiClient,
  () => '/dataLabs/afterMarketMatrix/download'
);
export type DownloadAftermarketMatrixResponse = UnpackPromise<
  ReturnType<typeof downloadAftermarketMatrix>
>;
