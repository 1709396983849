import { makeModificationSummary, UUID } from '../../../core';
import { makeEntity } from '../../../utils/mocks';
import { CustomSectorDto, LiteCustomSectorDto } from '../types/CustomSector';
import { makeCustomSectorBase } from './CustomSectorBase.mock';
import { makeCustomSubSector } from './CustomSubSector.mock';

export const makeLiteCustomSector = makeEntity<LiteCustomSectorDto>({
  sectorName: 'mock-sector-name',
  sectorId: 'mock-sector-id',
});

export const makeCustomSector = makeEntity<CustomSectorDto>({
  ...makeCustomSectorBase(),
  subSectors: [makeCustomSubSector()],
  sectorId: makeCustomSubSector().parentId as UUID,
  assignedCompanies: 3,
  colorCode: 0,
  lastModified: makeModificationSummary(),
});
