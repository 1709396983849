import { makeEntity } from '../../utils/mocks';
import { DataLabTableSummary } from '../types';

export const makeTableSummaryValues = makeEntity<DataLabTableSummary['values']>({
  mean: null,
  median: null,
  count: null,
  max: null,
  min: null,
  sum: null,
  yoy: null,
});

export const makeTableSummaryField = makeEntity<DataLabTableSummary>({
  field: 'summaryField',
  values: makeTableSummaryValues(),
});
