import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { FoPricingChartsDto, FoPricingChartsQuery } from './types/FoPricingCharts';
import {
  FoPricingDownloadQuery,
  FoPricingTableDto,
  FoPricingTableQuery,
} from './types/FoPricingTable';

export const fetchFoPricingCharts = makePostRequest<FoPricingChartsQuery, FoPricingChartsDto>(
  apiClient,
  () => '/dataLabs/followOnPricing/chart'
);
export type FetchFoPricingChartsResponse = UnpackPromise<ReturnType<typeof fetchFoPricingCharts>>;

export const fetchFoPricingTable = makePostRequest<FoPricingTableQuery, FoPricingTableDto>(
  apiClient,
  () => '/dataLabs/followOnPricing/table'
);
export type FetchFoPricingTableResponse = UnpackPromise<ReturnType<typeof fetchFoPricingTable>>;

export const downloadFoPricing = makePostDownloadRequest<FoPricingDownloadQuery>(
  apiClient,
  () => '/dataLabs/followOnPricing/download'
);
export type DownloadFoPricingResponse = UnpackPromise<ReturnType<typeof downloadFoPricing>>;
