import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { FoStructureChartsDto, FoStructureChartsQuery } from './types/FoStructureCharts';
import {
  FoStructureDownloadQuery,
  FoStructureTableDto,
  FoStructureTableQuery,
} from './types/FoStructureTable';

export const fetchFoStructureCharts = makePostRequest<FoStructureChartsQuery, FoStructureChartsDto>(
  apiClient,
  () => '/dataLabs/followOnStructure/chart'
);

export const fetchFoStructureTable = makePostRequest<FoStructureTableQuery, FoStructureTableDto>(
  apiClient,
  () => '/dataLabs/followOnStructure/table'
);
export type FetchFoStructureTableResponse = UnpackPromise<ReturnType<typeof fetchFoStructureTable>>;

export const downloadFoStructure = makePostDownloadRequest<FoStructureDownloadQuery>(
  apiClient,
  () => '/dataLabs/followOnStructure/download'
);
export type DownloadFoStructureResponse = UnpackPromise<ReturnType<typeof downloadFoStructure>>;
