import { DateRangePresetTypes, dateRangeUtil, Option } from '@cmg/common';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo } from 'react';

import {
  Country,
  EnumLabelOfCountry,
  EnumLabelOfSector,
  EnumLabelOfSubSector,
  OfferingType,
  Region,
  Sector,
  SubSector,
} from '../../../../graphql/__generated__';
import { DatalabScreens } from '../../../datalab/constants';
import { OfferingAttributesFragment } from './graphql/__generated__/GetLeftLead';
import { ManagerFragment, OfferingFragment } from './graphql/__generated__/GetUnderwriters';
import { OfferingsFilterForm_AdviserPartsFragment } from './graphql/__generated__/OfferingsFilterForm_Advisers';
import { OfferingsFilterForm_CountriesQuery } from './graphql/__generated__/OfferingsFilterForm_Countries';
import { OfferingsFilterForm_CustomSectorsQuery } from './graphql/__generated__/OfferingsFilterForm_CustomSectors';
import { OfferingsFilterForm_SectorsQuery } from './graphql/__generated__/OfferingsFilterForm_Sectors';
import { OfferingsFilterForm_ShareholderPartsFragment } from './graphql/__generated__/OfferingsFilterForm_Shareholders';
import {
  InternalOfferingType,
  OfferingsFilterOfferingType,
  useOfferingTypes,
} from './hooks/useOfferingTypes';

export type OfferingsFilterFormType = {
  date: {
    start?: string | null;
    end?: string | null;
    type?: string | null;
  };
  offeringTypes?: OfferingsFilterOfferingType[];
  marketCap: {
    max?: number | null;
    min?: number | null;
  };
  grossProceedsBase: {
    max?: number | null;
    min?: number | null;
  };
  latestProgramSize: {
    max?: number | null;
    min?: number | null;
  };
  sellingShareholderPct: {
    max?: number | null;
    min?: number | null;
  };
  sectors: (`SECTOR:${Sector}` | `SUB_SECTOR:${SubSector}`)[] | null;
  customSectors: string[] | null;
  countries: Country[] | null;
  underwriters: string[] | null;
  leftleads: string[] | null;
  shareholders: string[] | null;
  advisers: string[] | null;
  include144A?: boolean;
};

export { InternalOfferingType };

const excludedInitialOfferingTypes = [InternalOfferingType.IPO_SPACS, OfferingType.Rights];

export const useGetInitialFilterValues = ({
  screen,
  excludedOfferingTypes,
}: {
  screen?: DatalabScreens;
  excludedOfferingTypes?: OfferingType[];
}): OfferingsFilterFormType => {
  const { offeringTypeOptions } = useOfferingTypes({ excludedOfferingTypes });

  return React.useMemo(
    () => ({
      date: dateRangeUtil.getPreset(DateRangePresetTypes.LTM).value,
      offeringTypes:
        screen === DatalabScreens.GLOBAL_LEAGUE
          ? offeringTypeOptions
              .filter(
                offeringTypeOption =>
                  !excludedInitialOfferingTypes.includes(offeringTypeOption.value)
              )
              .map(({ value }) => value)
          : undefined,
      marketCap: {},
      grossProceedsBase: {},
      latestProgramSize: {},
      sellingShareholderPct: {},
      sectors: [],
      customSectors: [],
      countries: [],
      underwriters: [],
      leftleads: [],
      shareholders: [],
      advisers: [],
      include144A:
        screen === DatalabScreens.CONVERTS || screen === DatalabScreens.GLOBAL_ECM
          ? true
          : undefined,
    }),
    [screen, offeringTypeOptions]
  );
};

export const useCreateUnderwriterOptions = (
  offerings: OfferingFragment[],
  searchText?: string
): Option<string>[] => {
  return useMemo(() => {
    const managers: ManagerFragment[] = offerings.flatMap(offering =>
      offering.managers?.flatMap(offeringManager => offeringManager.manager ?? [])
    );
    const uniqueManagerIds = new Set<string>();
    const options: Option<string>[] = [];
    for (const { id, name } of managers) {
      if (id && name && !uniqueManagerIds.has(id)) {
        uniqueManagerIds.add(id);
        const isMatchingSearch =
          !searchText || name.toLowerCase().includes(searchText.toLowerCase());
        if (isMatchingSearch) {
          options.push({ value: id, label: name });
        }
      }
    }
    return options;
  }, [offerings, searchText]);
};

export const useCreateLeftleadOptions = (
  attributes: OfferingAttributesFragment[]
): Option<string>[] =>
  React.useMemo(() => {
    return attributes.reduce<Option<string>[]>((acc, { leftLeadId, leftLeadName }) => {
      if (leftLeadId && leftLeadName && !acc.find(item => item.value === leftLeadId)) {
        acc.push({ value: leftLeadId, label: leftLeadName });
      }
      return acc;
    }, []);
  }, [attributes]);

export const useCreateShareholderOptions = (
  items: OfferingsFilterForm_ShareholderPartsFragment[]
): Option<string>[] =>
  React.useMemo(() => {
    return items.reduce<Option<string>[]>((acc, { id, name }) => {
      if (id && name && !acc.find(item => item.value === id)) {
        acc.push({ value: id, label: name });
      }
      return acc;
    }, []);
  }, [items]);

export const useCreateAdviserOptions = (
  items: OfferingsFilterForm_AdviserPartsFragment[]
): Option<string>[] =>
  React.useMemo(() => {
    return items.reduce<Option<string>[]>((acc, { id, name }) => {
      if (id && name && !acc.find(item => item.value === id)) {
        acc.push({ value: id, label: name });
      }
      return acc;
    }, []);
  }, [items]);

export type SectorOption = {
  value: string;
  title: string;
  children?: { value: string; title: string }[];
};

// workaround the `sector.children` inavailability - see the useFetchSectors for context
// TBD: remove once the sector-subSector relation is available via graphql
type SectorWithSubSectors = EnumLabelOfSector & { children?: EnumLabelOfSubSector[] };

export const useCreateSectorOptions = (data?: OfferingsFilterForm_SectorsQuery): SectorOption[] =>
  React.useMemo(
    () =>
      uniqBy(data?.sectors?.items ?? [], 'id').map(s => ({
        title: s.displayName,
        value: `SECTOR:${s.id}`,
        // typecasting since the workaround desribed above applies
        children: uniqBy((s as unknown as SectorWithSubSectors).children || [], 'id').map(sub => ({
          title: sub.displayName,
          value: `SUB_SECTOR:${sub.id}`,
        })),
      })),
    [data]
  );

export type CustomSectorOption = {
  value: string;
  title: string;
  children?: { value: string; title: string }[];
};

export const useCreateCustomSectorOptions = (
  data?: OfferingsFilterForm_CustomSectorsQuery
): CustomSectorOption[] =>
  React.useMemo(
    () =>
      sortBy(
        (data?.customSectors?.items ?? [])
          .filter(s => !!s.name)
          .map(s => ({
            title: s.name!,
            value: `SECTOR:${s.id}`,
            children: sortBy(
              (s.children || [])
                .filter(s => !!s.name)
                .map(sub => ({
                  title: sub.name!,
                  value: `SUB_SECTOR:${sub.id}`,
                })),
              'title'
            ),
          })),
        'title'
      ),
    [data]
  );

// These properties exist in the filter object, but should not be
// included when calculating the active filter count.
const ignoredFormFields = ['date'];

export const useGetUpdatedFilterCount = (fields: OfferingsFilterFormType): number =>
  React.useMemo(() => {
    return Object.keys(fields)
      .filter(field => !ignoredFormFields.includes(field))
      .reduce((acc, currentField) => {
        const filterField = fields[currentField];
        if (filterField) {
          const isBooleanField = typeof filterField === 'boolean';
          const isStringField = typeof filterField === 'string';
          // Calculation behavior for range fields - if the min or max values
          // are greater than 1, increment the active filter count by 1.
          const isRangeField =
            (filterField?.min && Number(filterField.min) >= 0) ||
            (filterField?.max && Number(filterField.max) >= 0);

          if (Array.isArray(filterField)) {
            // Calculation behavior for array fields - if the array
            // length is greater than 1, increment the active filter count by 1.
            return acc + (filterField.length > 0 ? 1 : 0);
          } else if (isRangeField || isBooleanField || isStringField) {
            return acc + 1;
          }
        }
        return acc;
      }, 0);
  }, [fields]);

export type RegionCountryOption = {
  value: string;
  title: string;
  children: { value: string; title: string }[];
};

// workaround the `country.region` inavailability - see the useFetchRegionsAndCountries for context
// TBD: remove once the region field is available via graphql
type CountryWithRegion = EnumLabelOfCountry & { region?: Region };

export const useCreateRegionCountryOptions = (
  data?: OfferingsFilterForm_CountriesQuery
): RegionCountryOption[] =>
  React.useMemo(() => {
    const regionsOrdered = [Region.Namr, Region.Lamr, Region.Apac, Region.Emea];
    // typecasting since the workaround desribed above applies
    const countriesByRegion = groupBy(
      (data?.countries?.items ?? []) as CountryWithRegion[],
      'region'
    );

    return regionsOrdered
      .filter(regionId => !!countriesByRegion[regionId])
      .map(regionId => ({
        title: regionId,
        value: regionId,
        children: sortBy(uniqBy(countriesByRegion[regionId], 'id'), 'value').map(country => ({
          title: country.displayName,
          value: country.id,
        })),
      }));
  }, [data]);
