import { makeEntity } from '../../../utils/mocks';
import { makeOfferToHeatMap } from '../../mocks';
import { UnderwriterProfileChartsDto } from '../types/UnderwriterPofileCharts';

export const makeUnderwriterProfileChartsDto = makeEntity<UnderwriterProfileChartsDto>({
  sectorComposition: [],
  performanceBySector: [],
  averageEconomicsRole: [],
  averageEconomicsSector: [],
  averageFeeRoleSector: {
    totalFees: [],
    totalOfferings: [],
    averageFee: [],
  },
  historicalOfferingVolumeByType: [],
  feesByType: [],
  feesBySector: [],
  performanceHeatMapByOfferingType: makeOfferToHeatMap(),
});
