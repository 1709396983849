import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { UsEcmChartsDto, UsEcmChartsQuery } from './types/UsEcmCharts';
import { UsEcmTableDto, UsEcmTableQuery } from './types/UsEcmTable';

export const fetchMarketPulseCharts = makePostRequest<UsEcmChartsQuery, UsEcmChartsDto>(
  apiClient,
  () => '/dataLabs/marketPulse/chart'
);
export type FetchMarketPulseChartsResponse = UnpackPromise<
  ReturnType<typeof fetchMarketPulseCharts>
>;

export const fetchMarketPulseTable = makePostRequest<UsEcmTableQuery, UsEcmTableDto>(
  apiClient,
  () => '/dataLabs/marketPulse/table'
);
export type FetchMarketPulseTableResponse = UnpackPromise<ReturnType<typeof fetchMarketPulseTable>>;

export const downloadMarketPulse = makePostDownloadRequest<UsEcmTableQuery>(
  apiClient,
  () => '/dataLabs/marketPulse/download'
);
export type DownloadMarketPulseResponse = UnpackPromise<ReturnType<typeof downloadMarketPulse>>;
