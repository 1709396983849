/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import {
  Atm_Attributes_FieldsFragmentDoc,
  Atm_Selldown_FieldsFragmentDoc,
  Atm_Selldown_Term_FieldsFragmentDoc,
  AtmOfferings_Selldown_FieldsFragmentDoc,
} from '../../../../../common/graphql/__generated__/commonFragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AtmOfferings_ListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.OfferingSortInput> | Types.OfferingSortInput>;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
  hasSellDownPermission: Types.Scalars['Boolean'];
}>;

export type AtmOfferings_ListQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename?: 'OfferingCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Offering';
      id: string;
      type: Types.OfferingType;
      typeDisplayName?: string | null;
      securityType?: Types.SecurityType | null;
      securityTypeDisplayName?: string | null;
      marketTiming?: Types.MarketTiming | null;
      marketTimingDisplayName?: string | null;
      pricingCurrency?: Types.Currency | null;
      status: Types.OfferingStatus;
      statusDisplayName?: string | null;
      publicFilingDate?: string | null;
      hasForwardAgreement?: boolean | null;
      issuer?: {
        __typename?: 'Issuer';
        cik?: string | null;
        name?: string | null;
        sector?: Types.Sector | null;
        sectorDisplayName?: string | null;
        primarySymbol?: string | null;
      } | null;
      advisers?: Array<{
        __typename?: 'OfferingAdviser';
        role?: Types.AdviserRole | null;
        roleDisplayName?: string | null;
        adviser?: { __typename?: 'Adviser'; name?: string | null } | null;
      }> | null;
      managers: Array<{
        __typename?: 'OfferingManager';
        pctUnderwritingBaseShares?: number | null;
        underwritingBaseShares?: number | null;
        name?: string | null;
        role?: Types.OfferingManagerRole | null;
        roleDisplayName?: string | null;
        sequence?: number | null;
      }>;
      attributes?: {
        __typename?: 'OfferingAttributes';
        latestGrossProceedsBaseUsd?: number | null;
        leftLeadName?: string | null;
        leftLeadId?: string | null;
        terminatedDate?: string | null;
        overAllotmentExercised?: number | null;
        sizeUsd?: number | null;
        latestSizeInSecuritiesTotal?: number | null;
        marketCapPreOffering?: number | null;
        pctMarketCapPreOffering?: number | null;
        marketCapAtPricing?: number | null;
        marketCapAtPricingUsd?: number | null;
        pctMarketCapAtPricing?: number | null;
        preOfferingAdtv?: number | null;
        lastTradeBeforeFilingUsd?: number | null;
        latestGrossProceedsTotal?: number | null;
        pctOfferToOpen?: number | null;
        pctOfferTo1Day?: number | null;
        pctOfferTo3Day?: number | null;
        pctOfferTo7Day?: number | null;
        pctOfferTo14Day?: number | null;
        pctOfferTo30Day?: number | null;
        pctOfferTo90Day?: number | null;
        pctOfferTo180Day?: number | null;
        pctOfferToCurrent?: number | null;
      } | null;
      atmAttributes?: {
        __typename?: 'AtmAttributes';
        effectiveDate?: string | null;
        pctGrossSpread?: number | null;
        lastTradeBeforeFilingSplitAdjusted?: number | null;
        latestAsOfDate?: string | null;
        latestProgramSize?: number | null;
        latestProgramSizeInSecurities?: number | null;
        announcedProgramSize?: number | null;
        announcedProgramSizeInSecurities?: number | null;
        totalSales?: number | null;
        totalSalesInSecurities?: number | null;
        totalProgramRemaining?: number | null;
        totalProgramRemainingInSecurities?: number | null;
      } | null;
      terms?: Array<{
        __typename?: 'Term';
        type?: Types.TermType | null;
        revisedDate?: string | null;
        atmProgramSize?: number | null;
        atmProgramSizeInSecurities?: number | null;
      }>;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
    summaries?: {
      __typename?: 'OfferingListSummary';
      aggregations?: {
        __typename?: 'OfferingAggregations';
        pctOfferToOpen?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo1Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo3Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo7Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo14Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo30Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo90Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo180Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferToCurrent?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const AtmOfferings_ListDocument = gql`
  query ATMOfferings_List(
    $skip: Int
    $take: Int
    $order: [OfferingSortInput!]
    $where: OfferingFilterInput
    $hasSellDownPermission: Boolean!
  ) {
    offerings(skip: $skip, take: $take, order: $order, where: $where) {
      items {
        id
        issuer {
          cik
          name
          sector
          sectorDisplayName
          primarySymbol
        }
        type
        typeDisplayName
        securityType
        securityTypeDisplayName
        marketTiming
        marketTimingDisplayName
        pricingCurrency
        status
        statusDisplayName
        publicFilingDate
        hasForwardAgreement
        advisers {
          role
          roleDisplayName
          adviser {
            name
          }
        }
        managers {
          pctUnderwritingBaseShares
          underwritingBaseShares
          name
          role
          roleDisplayName
          sequence
        }
        attributes {
          latestGrossProceedsBaseUsd
          leftLeadName
          leftLeadId
          terminatedDate
          overAllotmentExercised
          sizeUsd
          latestSizeInSecuritiesTotal
          marketCapPreOffering
          pctMarketCapPreOffering
          marketCapAtPricing
          marketCapAtPricingUsd
          pctMarketCapAtPricing
          preOfferingAdtv
          lastTradeBeforeFilingUsd
          latestGrossProceedsTotal
          pctOfferToOpen
          pctOfferTo1Day
          pctOfferTo3Day
          pctOfferTo7Day
          pctOfferTo14Day
          pctOfferTo30Day
          pctOfferTo90Day
          pctOfferTo180Day
          pctOfferToCurrent
        }
        atmAttributes {
          effectiveDate
          ...Atm_Attributes_Fields
          ...AtmOfferings_Selldown_Fields @include(if: $hasSellDownPermission)
        }
        terms @include(if: $hasSellDownPermission) {
          ...Atm_Selldown_Term_Fields
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
      summaries {
        aggregations {
          pctOfferToOpen {
            min
            max
            mean
          }
          pctOfferTo1Day {
            min
            max
            mean
          }
          pctOfferTo3Day {
            min
            max
            mean
          }
          pctOfferTo7Day {
            min
            max
            mean
          }
          pctOfferTo14Day {
            min
            max
            mean
          }
          pctOfferTo30Day {
            min
            max
            mean
          }
          pctOfferTo90Day {
            min
            max
            mean
          }
          pctOfferTo180Day {
            min
            max
            mean
          }
          pctOfferToCurrent {
            min
            max
            mean
          }
        }
      }
    }
  }
  ${Atm_Attributes_FieldsFragmentDoc}
  ${AtmOfferings_Selldown_FieldsFragmentDoc}
  ${Atm_Selldown_Term_FieldsFragmentDoc}
`;

/**
 * __useAtmOfferings_ListQuery__
 *
 * To run a query within a React component, call `useAtmOfferings_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtmOfferings_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtmOfferings_ListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *      hasSellDownPermission: // value for 'hasSellDownPermission'
 *   },
 * });
 */
export function useAtmOfferings_ListQuery(
  baseOptions: Apollo.QueryHookOptions<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables> &
    ({ variables: AtmOfferings_ListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables>(
    AtmOfferings_ListDocument,
    options
  );
}
export function useAtmOfferings_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables>(
    AtmOfferings_ListDocument,
    options
  );
}
export function useAtmOfferings_ListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AtmOfferings_ListQuery, AtmOfferings_ListQueryVariables>(
    AtmOfferings_ListDocument,
    options
  );
}
export type AtmOfferings_ListQueryHookResult = ReturnType<typeof useAtmOfferings_ListQuery>;
export type AtmOfferings_ListLazyQueryHookResult = ReturnType<typeof useAtmOfferings_ListLazyQuery>;
export type AtmOfferings_ListSuspenseQueryHookResult = ReturnType<
  typeof useAtmOfferings_ListSuspenseQuery
>;
export type AtmOfferings_ListQueryResult = Apollo.QueryResult<
  AtmOfferings_ListQuery,
  AtmOfferings_ListQueryVariables
>;
