import { makeEntity } from '../../../utils/mocks';
import { FoStructureChartsDto, SizeAsAMultOfAdtv } from '../types/FoStructureCharts';

export const makeSizeAsAMultOfAdtv = makeEntity<SizeAsAMultOfAdtv>({
  label: 'Size As A Mult Of Adtv mock',
  values: {
    count: 140,
    mean: 22,
    median: 12,
  },
});

export const makeFoStructureChartsDto = makeEntity<FoStructureChartsDto>({
  historicalOfferingVolume: [],
  offeringVolumeBySector: [],
  sizeAsAMultOfAdtv: [],
  sizePrePostOfferingMarketCap: [],
});
