import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  UnderwriterOfferingsDownloadQuery,
  UnderwriterOfferingsTableDto,
  UnderwriterOfferingsTableQuery,
} from './types/UnderwriterOfferingsTable';

export const fetchUnderwriterOfferingsTable = makePostRequest<
  UnderwriterOfferingsTableQuery,
  UnderwriterOfferingsTableDto
>(apiClient, () => '/dataLabs/underwriterOfferings/table');
export type FetchUnderwriterOfferingsTableResponse = UnpackPromise<
  ReturnType<typeof fetchUnderwriterOfferingsTable>
>;

export const downloadUnderwriterOfferings =
  makePostDownloadRequest<UnderwriterOfferingsDownloadQuery>(
    apiClient,
    () => '/dataLabs/underwriterOfferings/download'
  );
export type DownloadUnderwriterOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadUnderwriterOfferings>
>;
