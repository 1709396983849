import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  UsEcmMatrixChartsDto,
  UsEcmMatrixChartsQuery,
  UsEcmMatrixDownloadQuery,
} from './types/UsEcmMatrixCharts';

export const fetchCapitalRaisedMatrixCharts = makePostRequest<
  UsEcmMatrixChartsQuery,
  UsEcmMatrixChartsDto
>(apiClient, () => '/dataLabs/capitalRaisedMatrix/chart');

export type FetchCapitalRaisedMatrixChartsResponse = UnpackPromise<
  ReturnType<typeof fetchCapitalRaisedMatrixCharts>
>;

export const downloadCapitalRaisedMatrixCharts = makePostDownloadRequest<UsEcmMatrixDownloadQuery>(
  apiClient,
  () => '/dataLabs/capitalRaisedMatrix/download'
);

export type DownloadCapitalRaisedMatrixChartsResponse = UnpackPromise<
  ReturnType<typeof downloadCapitalRaisedMatrixCharts>
>;
