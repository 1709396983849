import { makeEntity } from '../../utils/mocks';
import {
  AfterMarketRelativeToPricing,
  Average,
  AverageValue,
  FollowOnSummary,
  Graph,
  IpoSummary,
  Line,
  OfferingByTypeInfo,
  OfferingInfo,
  OfferToHeatMap,
  Point,
  Pricing,
  PricingVsInitialRange,
  SizePrePostOfferingMarketCap,
  Summary,
  TurnoverHeatMap,
  ValueBySector,
} from '../types/ChartValues';

export const makeValueBySector = makeEntity<ValueBySector>({
  colorCode: 42,
  label: 'Mock Sector',
  value: 666,
  count: 7,
});

export const makeOfferToHeatMap = makeEntity<OfferToHeatMap>({
  minValue: 0,
  maxValue: 0,
  items: [],
});

export const makeLine = makeEntity<Line>({
  x: 0,
  y: 1,
});

export const makePoint = makeEntity<Point>({
  ticker: 'Mock point',
  x: 0,
  y: 1,
});

export const makeGraph = makeEntity<Graph>({
  line: [makeLine({ x: 0, y: 0 }), makeLine({ x: 0, y: 1 }), makeLine({ x: 1, y: 1 })],
  points: [makePoint({ x: 1, y: 1 }), makePoint({ x: 2, y: 1 }), makePoint({ x: 3, y: 2 })],
});

export const makePricing = makeEntity<Pricing>({
  offerToCurrent: makeGraph(),
  offerToDay: makeGraph(),
  offerToMonth: makeGraph(),
  offerToOpen: makeGraph(),
  offerToSixMonths: makeGraph(),
  offerToThreeMonths: makeGraph(),
});

export const makeAverageValue = makeEntity<AverageValue>({
  count: 42,
  mean: 10,
  median: 8,
});

export const makeAverage = makeEntity<Average>({
  label: 'Mock average',
  values: makeAverageValue(),
});

export const makeAfterMarketRelativeToPricing = makeEntity<AfterMarketRelativeToPricing>({
  deal_pct_at_pricing_market_cap: makePricing(),
  price_vs_midpoint: makePricing(),
});

export const makeTurnoverHeatMap = makeEntity<TurnoverHeatMap>({
  items: [],
  maxValue: 42,
  minValue: 0,
});

export const makePricingVsInitialRange = makeEntity<PricingVsInitialRange>({
  label: 'Mock pricing vs initial range',
  values: {},
});

export const makeSizePrePostOfferingMarketCap = makeEntity<SizePrePostOfferingMarketCap>({
  label: '< $100M',
  values: {
    postOfferingCount: 23,
    postOfferingMean: 0.184093,
    postOfferingMedian: 0.178624,
    preOfferingCount: 23,
    preOfferingMean: 0.240238,
    preOfferingMedian: 0.207006,
  },
});

export const makeSummary = makeEntity<Summary>({
  current: 42484,
  day1: 23378,
  day30: 36831,
});

export const makeIpoSummary = makeEntity<IpoSummary>({
  ...makeSummary(),
  above: 1223,
  below: 666,
  within: 3543,
});

export const makeFollowOnSummary = makeEntity<FollowOnSummary>({
  ...makeSummary(),
  fileToOffer: 314551,
  toLastTrade: 452523,
});

export const makeOfferingInfo = makeEntity<OfferingInfo>({
  price: 1356437,
  quantity: 5000,
});

export const makeOfferingByTypeInfo = makeEntity<OfferingByTypeInfo>({
  followOn: makeOfferingInfo({
    price: 21343143,
    quantity: 31241,
  }),
  ipo: makeOfferingInfo({
    price: 1234535,
    quantity: 41353,
  }),
});
