/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Calendar_Offering_IssuerFieldsFragment = {
  __typename?: 'Issuer';
  id?: string | null;
  name?: string | null;
  primarySymbol?: string | null;
  sector?: Types.Sector | null;
  sectorDisplayName?: string | null;
  customSectors?: {
    __typename?: 'CustomSectorIssuer';
    customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
  } | null;
};

export type Calendar_OfferingAttributes_ShareholderAttributesFieldsFragment = {
  __typename?: 'ShareholderAttributes';
  name: string;
  preOfferingOwnership?: number | null;
  pctPreOfferingOwnership?: number | null;
  postOfferingOwnership?: number | null;
  pctPostOfferingOwnership?: number | null;
};

export type Calendar_Offering_OfferingAttributesFieldsFragment = {
  __typename?: 'OfferingAttributes';
  status: Types.OfferingStatus;
  type: Types.OfferingType;
  typeDisplayName?: string | null;
  exchangeCountry?: Types.Country | null;
  exchangeCountryDisplayName?: string | null;
  exchangeRegion?: Types.Region | null;
  exchangeRegionDisplayName?: string | null;
  isAccelerated?: boolean | null;
  launchDate?: string | null;
  publicFilingDate?: string | null;
  isRule144A?: boolean | null;
  isRegS?: boolean | null;
  latestGrossProceedsTotal?: number | null;
  latestGrossProceedsTotalUsd?: number | null;
  latestSizeInSecuritiesTotal?: number | null;
  initialSizeInSecuritiesTotal?: number | null;
  priceRangeLow?: number | null;
  priceRangeHigh?: number | null;
  revisedIpoRangeLowUsd?: number | null;
  revisedIpoRangeHighUsd?: number | null;
  initialIpoRangeLowUsd?: number | null;
  initialIpoRangeHighUsd?: number | null;
  pricingCurrency?: Types.Currency | null;
  pricingDate?: string | null;
  marketCapAtPricing?: number | null;
  marketCapAtPricingUsd?: number | null;
  marketCapPreOffering?: number | null;
  marketCapPreOfferingUsd?: number | null;
  leftLeadName?: string | null;
  leftLeadId?: string | null;
  primaryShareholderName?: string | null;
  primaryShareholderFirmId?: string | null;
  latestOfferPrice?: number | null;
  latestOfferPriceUsd?: number | null;
  pctOfferToCurrent?: number | null;
  currentSharePrice?: number | null;
  firstTradeDate?: string | null;
  pctToLastTrade?: number | null;
  lastTradeBeforeOffer?: number | null;
  lastTradeBeforeOfferUsd?: number | null;
  pctOfferTo1Day?: number | null;
  day1SharePrice?: number | null;
  postponedDate?: string | null;
  lockUpExpirationDate?: string | null;
  isConditionalLockUp?: boolean | null;
  latestPctSecondaryShares?: number | null;
  totalBookrunners?: number | null;
  totalNonBookrunners?: number | null;
  hasMultipleLockUps?: boolean | null;
  hasCornerstoneInvestors?: boolean | null;
  cornerstoneOfferingParticipantsAmount?: number | null;
  cornerstonePrivatePlacementAmount?: number | null;
  cornerstoneTotalAmount?: number | null;
  cornerstoneInvestorsTotalCount?: number | null;
  cornerstoneInvestorsOfferingParticipantsCount?: number | null;
  cornerstoneInvestorsPrivatePlacementCount?: number | null;
  latestCouponTalkPercentageLow?: number | null;
  latestCouponTalkPercentageHigh?: number | null;
  latestPremiumTalkLowPercentage?: number | null;
  latestPremiumTalkHighPercentage?: number | null;
  shareholders?: Array<{
    __typename?: 'ShareholderAttributes';
    name: string;
    preOfferingOwnership?: number | null;
    pctPreOfferingOwnership?: number | null;
    postOfferingOwnership?: number | null;
    pctPostOfferingOwnership?: number | null;
  }> | null;
};

export type Calendar_Offering_OfferingManagerFieldsFragment = {
  __typename?: 'OfferingManager';
  roleDisplayName?: string | null;
  name?: string | null;
  managerId?: string | null;
  pctUnderwritingBaseShares?: number | null;
};

export type Calendar_Offering_AllocationFieldsFragment = {
  __typename?: 'Allocation';
  id: string;
  ioiType?: Types.IoiType | null;
};

export type Calendar_Offering_FirmMetricFieldsFragment = {
  __typename?: 'FirmMetric';
  id: string;
  allocation?: number | null;
  ioiShares?: number | null;
};

export type Calendar_Offering_IndicationOfInterestFieldsFragment = {
  __typename?: 'IndicationOfInterest';
  id: string;
  unitType?: Types.UnitType | null;
  shares?: number | null;
  dollars?: number | null;
  percentage?: number | null;
  pricingType?: Types.PricingType | null;
  limitPriceUsd?: number | null;
};

export type Calendar_Offering_TermFieldsFragment = {
  __typename?: 'Term';
  id?: string | null;
  couponTalkPercentageLow?: number | null;
  couponTalkPercentageHigh?: number | null;
  premiumTalkLowPercentage?: number | null;
  premiumTalkHighPercentage?: number | null;
  couponPercentage?: number | null;
  premiumPercentage?: number | null;
  conversionPrice?: number | null;
};

export type Calendar_Offering_CornerstoneInvestmentFragment = {
  __typename?: 'CornerstoneInvestment';
  id: string;
  type: Types.CornerstoneInvestmentType;
  individualAmount?: number | null;
  investors: Array<{
    __typename?: 'CornerstoneInvestor';
    id: string;
    isExistingCornerstoneInvestor: boolean;
    shareholder: {
      __typename?: 'Shareholder';
      id: string;
      name?: string | null;
      shareholderTypeDisplayName?: string | null;
    };
  }>;
};

export type Calendar_OfferingFieldsFragment = {
  __typename?: 'Offering';
  id: string;
  symbol?: string | null;
  issuer?: {
    __typename?: 'Issuer';
    id?: string | null;
    name?: string | null;
    primarySymbol?: string | null;
    sector?: Types.Sector | null;
    sectorDisplayName?: string | null;
    customSectors?: {
      __typename?: 'CustomSectorIssuer';
      customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
    } | null;
  } | null;
  attributes?: {
    __typename?: 'OfferingAttributes';
    status: Types.OfferingStatus;
    type: Types.OfferingType;
    typeDisplayName?: string | null;
    exchangeCountry?: Types.Country | null;
    exchangeCountryDisplayName?: string | null;
    exchangeRegion?: Types.Region | null;
    exchangeRegionDisplayName?: string | null;
    isAccelerated?: boolean | null;
    launchDate?: string | null;
    publicFilingDate?: string | null;
    isRule144A?: boolean | null;
    isRegS?: boolean | null;
    latestGrossProceedsTotal?: number | null;
    latestGrossProceedsTotalUsd?: number | null;
    latestSizeInSecuritiesTotal?: number | null;
    initialSizeInSecuritiesTotal?: number | null;
    priceRangeLow?: number | null;
    priceRangeHigh?: number | null;
    revisedIpoRangeLowUsd?: number | null;
    revisedIpoRangeHighUsd?: number | null;
    initialIpoRangeLowUsd?: number | null;
    initialIpoRangeHighUsd?: number | null;
    pricingCurrency?: Types.Currency | null;
    pricingDate?: string | null;
    marketCapAtPricing?: number | null;
    marketCapAtPricingUsd?: number | null;
    marketCapPreOffering?: number | null;
    marketCapPreOfferingUsd?: number | null;
    leftLeadName?: string | null;
    leftLeadId?: string | null;
    primaryShareholderName?: string | null;
    primaryShareholderFirmId?: string | null;
    latestOfferPrice?: number | null;
    latestOfferPriceUsd?: number | null;
    pctOfferToCurrent?: number | null;
    currentSharePrice?: number | null;
    firstTradeDate?: string | null;
    pctToLastTrade?: number | null;
    lastTradeBeforeOffer?: number | null;
    lastTradeBeforeOfferUsd?: number | null;
    pctOfferTo1Day?: number | null;
    day1SharePrice?: number | null;
    postponedDate?: string | null;
    lockUpExpirationDate?: string | null;
    isConditionalLockUp?: boolean | null;
    latestPctSecondaryShares?: number | null;
    totalBookrunners?: number | null;
    totalNonBookrunners?: number | null;
    hasMultipleLockUps?: boolean | null;
    hasCornerstoneInvestors?: boolean | null;
    cornerstoneOfferingParticipantsAmount?: number | null;
    cornerstonePrivatePlacementAmount?: number | null;
    cornerstoneTotalAmount?: number | null;
    cornerstoneInvestorsTotalCount?: number | null;
    cornerstoneInvestorsOfferingParticipantsCount?: number | null;
    cornerstoneInvestorsPrivatePlacementCount?: number | null;
    latestCouponTalkPercentageLow?: number | null;
    latestCouponTalkPercentageHigh?: number | null;
    latestPremiumTalkLowPercentage?: number | null;
    latestPremiumTalkHighPercentage?: number | null;
    shareholders?: Array<{
      __typename?: 'ShareholderAttributes';
      name: string;
      preOfferingOwnership?: number | null;
      pctPreOfferingOwnership?: number | null;
      postOfferingOwnership?: number | null;
      pctPostOfferingOwnership?: number | null;
    }> | null;
  } | null;
  managers: Array<{
    __typename?: 'OfferingManager';
    roleDisplayName?: string | null;
    name?: string | null;
    managerId?: string | null;
    pctUnderwritingBaseShares?: number | null;
  }>;
  indicationsOfInterest?: Array<{
    __typename?: 'IndicationOfInterest';
    id: string;
    unitType?: Types.UnitType | null;
    shares?: number | null;
    dollars?: number | null;
    percentage?: number | null;
    pricingType?: Types.PricingType | null;
    limitPriceUsd?: number | null;
  }> | null;
  lastAllocation?: { __typename?: 'Allocation'; id: string; ioiType?: Types.IoiType | null } | null;
  firmMetric?: {
    __typename?: 'FirmMetric';
    id: string;
    allocation?: number | null;
    ioiShares?: number | null;
  } | null;
  finalTerm?: {
    __typename?: 'Term';
    id?: string | null;
    couponTalkPercentageLow?: number | null;
    couponTalkPercentageHigh?: number | null;
    premiumTalkLowPercentage?: number | null;
    premiumTalkHighPercentage?: number | null;
    couponPercentage?: number | null;
    premiumPercentage?: number | null;
    conversionPrice?: number | null;
  } | null;
  cornerstoneInvestments?: Array<{
    __typename?: 'CornerstoneInvestment';
    id: string;
    type: Types.CornerstoneInvestmentType;
    individualAmount?: number | null;
    investors: Array<{
      __typename?: 'CornerstoneInvestor';
      id: string;
      isExistingCornerstoneInvestor: boolean;
      shareholder: {
        __typename?: 'Shareholder';
        id: string;
        name?: string | null;
        shareholderTypeDisplayName?: string | null;
      };
    }>;
  }> | null;
  userOfferings?: Array<{ __typename?: 'UserOffering'; id: string; isFollowing: boolean }> | null;
};

export type Calendar_OfferingListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
  order?: Types.InputMaybe<Array<Types.OfferingSortInput> | Types.OfferingSortInput>;
  hasIoiPermission: Types.Scalars['Boolean'];
  hasCustomSectorsPermission: Types.Scalars['Boolean'];
}>;

export type Calendar_OfferingListQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename?: 'OfferingCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Offering';
      id: string;
      symbol?: string | null;
      issuer?: {
        __typename?: 'Issuer';
        id?: string | null;
        name?: string | null;
        primarySymbol?: string | null;
        sector?: Types.Sector | null;
        sectorDisplayName?: string | null;
        customSectors?: {
          __typename?: 'CustomSectorIssuer';
          customSector?: { __typename?: 'CustomSector'; id: string; name?: string | null } | null;
        } | null;
      } | null;
      attributes?: {
        __typename?: 'OfferingAttributes';
        status: Types.OfferingStatus;
        type: Types.OfferingType;
        typeDisplayName?: string | null;
        exchangeCountry?: Types.Country | null;
        exchangeCountryDisplayName?: string | null;
        exchangeRegion?: Types.Region | null;
        exchangeRegionDisplayName?: string | null;
        isAccelerated?: boolean | null;
        launchDate?: string | null;
        publicFilingDate?: string | null;
        isRule144A?: boolean | null;
        isRegS?: boolean | null;
        latestGrossProceedsTotal?: number | null;
        latestGrossProceedsTotalUsd?: number | null;
        latestSizeInSecuritiesTotal?: number | null;
        initialSizeInSecuritiesTotal?: number | null;
        priceRangeLow?: number | null;
        priceRangeHigh?: number | null;
        revisedIpoRangeLowUsd?: number | null;
        revisedIpoRangeHighUsd?: number | null;
        initialIpoRangeLowUsd?: number | null;
        initialIpoRangeHighUsd?: number | null;
        pricingCurrency?: Types.Currency | null;
        pricingDate?: string | null;
        marketCapAtPricing?: number | null;
        marketCapAtPricingUsd?: number | null;
        marketCapPreOffering?: number | null;
        marketCapPreOfferingUsd?: number | null;
        leftLeadName?: string | null;
        leftLeadId?: string | null;
        primaryShareholderName?: string | null;
        primaryShareholderFirmId?: string | null;
        latestOfferPrice?: number | null;
        latestOfferPriceUsd?: number | null;
        pctOfferToCurrent?: number | null;
        currentSharePrice?: number | null;
        firstTradeDate?: string | null;
        pctToLastTrade?: number | null;
        lastTradeBeforeOffer?: number | null;
        lastTradeBeforeOfferUsd?: number | null;
        pctOfferTo1Day?: number | null;
        day1SharePrice?: number | null;
        postponedDate?: string | null;
        lockUpExpirationDate?: string | null;
        isConditionalLockUp?: boolean | null;
        latestPctSecondaryShares?: number | null;
        totalBookrunners?: number | null;
        totalNonBookrunners?: number | null;
        hasMultipleLockUps?: boolean | null;
        hasCornerstoneInvestors?: boolean | null;
        cornerstoneOfferingParticipantsAmount?: number | null;
        cornerstonePrivatePlacementAmount?: number | null;
        cornerstoneTotalAmount?: number | null;
        cornerstoneInvestorsTotalCount?: number | null;
        cornerstoneInvestorsOfferingParticipantsCount?: number | null;
        cornerstoneInvestorsPrivatePlacementCount?: number | null;
        latestCouponTalkPercentageLow?: number | null;
        latestCouponTalkPercentageHigh?: number | null;
        latestPremiumTalkLowPercentage?: number | null;
        latestPremiumTalkHighPercentage?: number | null;
        shareholders?: Array<{
          __typename?: 'ShareholderAttributes';
          name: string;
          preOfferingOwnership?: number | null;
          pctPreOfferingOwnership?: number | null;
          postOfferingOwnership?: number | null;
          pctPostOfferingOwnership?: number | null;
        }> | null;
      } | null;
      managers: Array<{
        __typename?: 'OfferingManager';
        roleDisplayName?: string | null;
        name?: string | null;
        managerId?: string | null;
        pctUnderwritingBaseShares?: number | null;
      }>;
      indicationsOfInterest?: Array<{
        __typename?: 'IndicationOfInterest';
        id: string;
        unitType?: Types.UnitType | null;
        shares?: number | null;
        dollars?: number | null;
        percentage?: number | null;
        pricingType?: Types.PricingType | null;
        limitPriceUsd?: number | null;
      }> | null;
      lastAllocation?: {
        __typename?: 'Allocation';
        id: string;
        ioiType?: Types.IoiType | null;
      } | null;
      firmMetric?: {
        __typename?: 'FirmMetric';
        id: string;
        allocation?: number | null;
        ioiShares?: number | null;
      } | null;
      finalTerm?: {
        __typename?: 'Term';
        id?: string | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        couponPercentage?: number | null;
        premiumPercentage?: number | null;
        conversionPrice?: number | null;
      } | null;
      cornerstoneInvestments?: Array<{
        __typename?: 'CornerstoneInvestment';
        id: string;
        type: Types.CornerstoneInvestmentType;
        individualAmount?: number | null;
        investors: Array<{
          __typename?: 'CornerstoneInvestor';
          id: string;
          isExistingCornerstoneInvestor: boolean;
          shareholder: {
            __typename?: 'Shareholder';
            id: string;
            name?: string | null;
            shareholderTypeDisplayName?: string | null;
          };
        }>;
      }> | null;
      userOfferings?: Array<{
        __typename?: 'UserOffering';
        id: string;
        isFollowing: boolean;
      }> | null;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      offset: number;
      pageSize: number;
    };
  } | null;
};

export const Calendar_Offering_IssuerFieldsFragmentDoc = gql`
  fragment Calendar_Offering_IssuerFields on Issuer {
    id
    name
    primarySymbol
    sector
    sectorDisplayName
    customSectors @include(if: $hasCustomSectorsPermission) {
      customSector {
        id
        name
      }
    }
  }
`;
export const Calendar_OfferingAttributes_ShareholderAttributesFieldsFragmentDoc = gql`
  fragment Calendar_OfferingAttributes_ShareholderAttributesFields on ShareholderAttributes {
    name
    preOfferingOwnership
    pctPreOfferingOwnership
    postOfferingOwnership
    pctPostOfferingOwnership
  }
`;
export const Calendar_Offering_OfferingAttributesFieldsFragmentDoc = gql`
  fragment Calendar_Offering_OfferingAttributesFields on OfferingAttributes {
    status
    type
    typeDisplayName
    exchangeCountry
    exchangeCountryDisplayName
    exchangeRegion
    exchangeRegionDisplayName
    isAccelerated
    launchDate
    publicFilingDate
    isRule144A
    isRegS
    latestGrossProceedsTotal
    latestGrossProceedsTotalUsd
    latestSizeInSecuritiesTotal
    initialSizeInSecuritiesTotal
    priceRangeLow
    priceRangeHigh
    revisedIpoRangeLowUsd
    revisedIpoRangeHighUsd
    initialIpoRangeLowUsd
    initialIpoRangeHighUsd
    pricingCurrency
    pricingDate
    marketCapAtPricing
    marketCapAtPricingUsd
    marketCapPreOffering
    marketCapPreOfferingUsd
    leftLeadName
    leftLeadId
    primaryShareholderName
    primaryShareholderFirmId
    shareholders {
      ...Calendar_OfferingAttributes_ShareholderAttributesFields
    }
    latestOfferPrice
    latestOfferPriceUsd
    pctOfferToCurrent
    currentSharePrice
    firstTradeDate
    pctToLastTrade
    lastTradeBeforeOffer
    lastTradeBeforeOfferUsd
    pctOfferTo1Day
    day1SharePrice
    postponedDate
    lockUpExpirationDate
    isConditionalLockUp
    latestPctSecondaryShares
    totalBookrunners
    totalNonBookrunners
    isConditionalLockUp
    hasMultipleLockUps
    hasCornerstoneInvestors
    cornerstoneOfferingParticipantsAmount
    cornerstonePrivatePlacementAmount
    cornerstoneTotalAmount
    cornerstoneInvestorsTotalCount
    cornerstoneInvestorsOfferingParticipantsCount
    cornerstoneInvestorsPrivatePlacementCount
    latestCouponTalkPercentageLow
    latestCouponTalkPercentageHigh
    latestPremiumTalkLowPercentage
    latestPremiumTalkHighPercentage
  }
  ${Calendar_OfferingAttributes_ShareholderAttributesFieldsFragmentDoc}
`;
export const Calendar_Offering_OfferingManagerFieldsFragmentDoc = gql`
  fragment Calendar_Offering_OfferingManagerFields on OfferingManager {
    roleDisplayName
    name
    managerId
    pctUnderwritingBaseShares
  }
`;
export const Calendar_Offering_IndicationOfInterestFieldsFragmentDoc = gql`
  fragment Calendar_Offering_IndicationOfInterestFields on IndicationOfInterest {
    id
    unitType
    shares
    dollars
    percentage
    pricingType
    limitPriceUsd
  }
`;
export const Calendar_Offering_AllocationFieldsFragmentDoc = gql`
  fragment Calendar_Offering_AllocationFields on Allocation {
    id
    ioiType
  }
`;
export const Calendar_Offering_FirmMetricFieldsFragmentDoc = gql`
  fragment Calendar_Offering_FirmMetricFields on FirmMetric {
    id
    allocation
    ioiShares
  }
`;
export const Calendar_Offering_TermFieldsFragmentDoc = gql`
  fragment Calendar_Offering_TermFields on Term {
    id
    couponTalkPercentageLow
    couponTalkPercentageHigh
    premiumTalkLowPercentage
    premiumTalkHighPercentage
    couponPercentage
    premiumPercentage
    conversionPrice
  }
`;
export const Calendar_Offering_CornerstoneInvestmentFragmentDoc = gql`
  fragment Calendar_Offering_CornerstoneInvestment on CornerstoneInvestment {
    id
    type
    individualAmount
    investors {
      id
      isExistingCornerstoneInvestor
      shareholder {
        id
        name
        shareholderTypeDisplayName
      }
    }
  }
`;
export const Calendar_OfferingFieldsFragmentDoc = gql`
  fragment Calendar_OfferingFields on Offering {
    id
    symbol
    issuer {
      ...Calendar_Offering_IssuerFields
    }
    attributes {
      ...Calendar_Offering_OfferingAttributesFields
    }
    managers {
      ...Calendar_Offering_OfferingManagerFields
    }
    indicationsOfInterest @include(if: $hasIoiPermission) {
      ...Calendar_Offering_IndicationOfInterestFields
    }
    lastAllocation {
      ...Calendar_Offering_AllocationFields
    }
    firmMetric {
      ...Calendar_Offering_FirmMetricFields
    }
    finalTerm {
      ...Calendar_Offering_TermFields
    }
    cornerstoneInvestments {
      ...Calendar_Offering_CornerstoneInvestment
    }
    userOfferings {
      id
      isFollowing
    }
  }
  ${Calendar_Offering_IssuerFieldsFragmentDoc}
  ${Calendar_Offering_OfferingAttributesFieldsFragmentDoc}
  ${Calendar_Offering_OfferingManagerFieldsFragmentDoc}
  ${Calendar_Offering_IndicationOfInterestFieldsFragmentDoc}
  ${Calendar_Offering_AllocationFieldsFragmentDoc}
  ${Calendar_Offering_FirmMetricFieldsFragmentDoc}
  ${Calendar_Offering_TermFieldsFragmentDoc}
  ${Calendar_Offering_CornerstoneInvestmentFragmentDoc}
`;
export const Calendar_OfferingListDocument = gql`
  query Calendar_OfferingList(
    $skip: Int
    $take: Int
    $where: OfferingFilterInput
    $order: [OfferingSortInput!]
    $hasIoiPermission: Boolean!
    $hasCustomSectorsPermission: Boolean!
  ) {
    offerings(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        ...Calendar_OfferingFields
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        offset
        pageSize
      }
      totalCount
    }
  }
  ${Calendar_OfferingFieldsFragmentDoc}
`;

/**
 * __useCalendar_OfferingListQuery__
 *
 * To run a query within a React component, call `useCalendar_OfferingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendar_OfferingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendar_OfferingListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      hasIoiPermission: // value for 'hasIoiPermission'
 *      hasCustomSectorsPermission: // value for 'hasCustomSectorsPermission'
 *   },
 * });
 */
export function useCalendar_OfferingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calendar_OfferingListQuery,
    Calendar_OfferingListQueryVariables
  > &
    ({ variables: Calendar_OfferingListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Calendar_OfferingListQuery, Calendar_OfferingListQueryVariables>(
    Calendar_OfferingListDocument,
    options
  );
}
export function useCalendar_OfferingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calendar_OfferingListQuery,
    Calendar_OfferingListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Calendar_OfferingListQuery, Calendar_OfferingListQueryVariables>(
    Calendar_OfferingListDocument,
    options
  );
}
export function useCalendar_OfferingListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        Calendar_OfferingListQuery,
        Calendar_OfferingListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Calendar_OfferingListQuery, Calendar_OfferingListQueryVariables>(
    Calendar_OfferingListDocument,
    options
  );
}
export type Calendar_OfferingListQueryHookResult = ReturnType<typeof useCalendar_OfferingListQuery>;
export type Calendar_OfferingListLazyQueryHookResult = ReturnType<
  typeof useCalendar_OfferingListLazyQuery
>;
export type Calendar_OfferingListSuspenseQueryHookResult = ReturnType<
  typeof useCalendar_OfferingListSuspenseQuery
>;
export type Calendar_OfferingListQueryResult = Apollo.QueryResult<
  Calendar_OfferingListQuery,
  Calendar_OfferingListQueryVariables
>;
