import { makeEntity } from '../../utils/mocks';
import { DataLabFilters } from '../types/DataLabFilters';
import { TimeWindow } from '../types/TimeWindow';

export const makeDataLabFilters = makeEntity<DataLabFilters>({
  customFilters: {
    rules: [],
  },
  advisoriesIds: [],
  customSectorIds: [],
  dateType: TimeWindow.CUSTOM,
  endDate: undefined,
  filterForAllocations: [],
  fundsIds: [],
  leftLeadOnly: undefined,
  marketCapMax: undefined,
  marketCapMin: undefined,
  offeringSizeMax: undefined,
  offeringSizeMin: undefined,
  offeringTypes: [],
  sectorCodes: [],
  sellingShareholderPctMax: undefined,
  sellingShareholderPctMin: undefined,
  spac: 'exclude',
  sponsorsIds: [],
  startDate: undefined,
  underwritersIds: [],
});
