import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  ParticipationSummaryChartsDto,
  ParticipationSummaryChartsQuery,
} from './types/IoiParticipationCharts';
import {
  ParticipationSummaryDownloadQuery,
  ParticipationSummaryTableDto,
  ParticipationSummaryTableQuery,
} from './types/IoiParticipationTable';

export const fetchIoiParticipationCharts = makePostRequest<
  ParticipationSummaryChartsQuery,
  ParticipationSummaryChartsDto
>(apiClient, () => '/dataLabs/ioiParticipation/chart');
export type FetchIoiParticipationChartsResponse = UnpackPromise<
  ReturnType<typeof fetchIoiParticipationCharts>
>;

export const fetchIoiParticipationTable = makePostRequest<
  ParticipationSummaryTableQuery,
  ParticipationSummaryTableDto
>(apiClient, () => '/dataLabs/ioiParticipation/table');
export type FetchIoiParticipationTableResponse = UnpackPromise<
  ReturnType<typeof fetchIoiParticipationTable>
>;

export const downloadIoiParticipation = makePostDownloadRequest<ParticipationSummaryDownloadQuery>(
  apiClient,
  () => '/dataLabs/ioiParticipation/download'
);
export type DownloadIoiParticipationResponse = UnpackPromise<
  ReturnType<typeof downloadIoiParticipation>
>;
