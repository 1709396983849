import { ISODate, ISODateTime, UUID } from '../../core';

export type CustomFilterRuleValue = number | ISODateTime | ISODate | string | boolean | UUID;

export type CustomFilterRuleDto = {
  criteria?: string;
  operatorType?: CustomFiltersOperatorType;
  values?: CustomFilterRuleValue[];
};

export enum CustomFiltersOperatorType {
  EQUAL_TO = 'EQUAL_TO',
  COMPOUND_EQUAL_TO = 'COMPOUND_EQUAL_TO',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LIKE = 'LIKE',
  BETWEEN = 'BETWEEN',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL',
}
