import { makeEntity } from '../../../utils/mocks';
import { UnderwritingTermsDashboardDto } from '../types/UnderwritingStructureCharts';

export const makeUnderwritingTermsDashboardDto = makeEntity<UnderwritingTermsDashboardDto>({
  allInCost: [],
  grossSpread: [],
  nrOfBookrunners: [],
  nrOfManagers: [],
  nrOfNonBookManagers: [],
  percentToLeftLead: [],
  totalPercentOfNonBooks: [],
  totalPercentToBookrunners: [],
});
