import { makeEntity } from '../../../utils/mocks';
import { makeOfferToHeatMap, makePricing } from '../../mocks';
import { FoPricingChartsDto } from '../types/FoPricingCharts';

export const makeFoPricingCharts = makeEntity<FoPricingChartsDto>({
  fileToOfferDiscount: [],
  discountToLastTrade: [],
  meanDiscountToLastTradeByOfferingType: [],
  medianDiscountToLastTradeByOfferingType: [],
  allInCost: [],
  firstDayTurnover: [],
  performanceHeatMapBySector: makeOfferToHeatMap(),
  performanceHeatMapByOfferingType: makeOfferToHeatMap(),
  firstDayVolumeDividedByDealShares: [],
  afterMarketRelativeToSizing: {
    performance: {
      market_cap: makePricing(),
      adtv: makePricing(),
    },
  },
  afterMarketRelativeToPricing: {
    performance: {
      file_to_offer_discount: makePricing(),
      discount_to_last_trade: makePricing(),
    },
  },
});
