import { addWeeks, addYears, previousSaturday, subWeeks, subYears } from 'date-fns';

import {
  Country,
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingAttributesFilterInput,
  OfferingFilterInput,
  OfferingSortInput,
  OfferingStatus,
  OfferingType,
  Sector,
  SortEnumType,
  SubSector,
} from '../../../graphql/__generated__/index';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { isInternationalOfferingsOn } from '../../datalab/model/utils';
import {
  CalendarOfferingType,
  FilterValues,
  InternalOfferingType,
} from '../model/calendar-filters';

export type { FilterValues };

const internalOfferingTypes: CalendarOfferingType[] = Object.values(InternalOfferingType);

export function getGraphqlWhere(
  calendarCategory: CalendarCategory | undefined,
  filters: FilterValues
): OfferingFilterInput {
  const { attributes: categoryWhereAttributes, ...categoryWhere } =
    (calendarCategory && getCategoryWhere(calendarCategory)) || {};
  const { or: categoryWhereAttributesOr, ...categoryWhereAttributesRest } =
    categoryWhereAttributes || {};

  const { attributes: filtersWhereAttributes, ...filtersWhere } = getFiltersWhere(filters);
  const { or: filtersWhereAttributesOr, ...filtersWhereAttributesRest } =
    filtersWhereAttributes || {};

  const type = filters.offeringType.filter(
    offeringType => !internalOfferingTypes.includes(offeringType)
  ) as OfferingType[];

  const hasSpac = filters.offeringType.includes(InternalOfferingType.IPO_SPACS);

  let isSpac = !hasSpac && type.includes(OfferingType.Ipo) ? { eq: false } : undefined;

  if (hasSpac && !type.includes(OfferingType.Ipo)) {
    type.push(OfferingType.Ipo);
    isSpac = { eq: true };
  }

  const combinedAttributes: OfferingAttributesFilterInput[] = [];
  categoryWhereAttributesOr &&
    combinedAttributes.push({
      or: categoryWhereAttributesOr,
    });
  filtersWhereAttributesOr &&
    combinedAttributes.push({
      or: filtersWhereAttributesOr,
    });

  return {
    type: type.length > 0 ? { in: type } : undefined,
    attributes: {
      ...categoryWhereAttributesRest,
      ...filtersWhereAttributesRest,
      ...(isSpac && { isSpac }),
      and: combinedAttributes,
    },
    ...categoryWhere,
    ...filtersWhere,
  };
}

export function getCategoryWhere(
  calendarCategory: CalendarCategory
): OfferingFilterInput | undefined {
  const now = new Date();
  const ago20y = subYears(now, 20).toISOString().split('T')[0];
  const ago2w = subWeeks(now, 2).toISOString().split('T')[0];
  const future1y = addYears(now, 1).toISOString().split('T')[0];
  const future20y = addYears(now, 20).toISOString().split('T')[0];
  const future2w = addWeeks(now, 2).toISOString().split('T')[0];
  const yearStart = `${now.getUTCFullYear()}-01-01`;
  const lastSaturday = previousSaturday(now).toISOString().split('T')[0];

  switch (calendarCategory) {
    case CalendarCategory.LIVE:
      return {
        attributes: {
          status: { eq: OfferingStatus.Live },
          or: [
            { publicFilingDate: { gte: ago20y, lte: future1y } },
            { publicFilingDate: { eq: null } },
          ],
        },
      };
    case CalendarCategory.PRICED:
      return {
        attributes: {
          status: { eq: OfferingStatus.Priced },
          or: [
            { firstTradeDate: { gte: ago2w, lte: future1y } },
            { firstTradeDate: { eq: null }, type: { neq: OfferingType.Convertible } },
          ],
        },
      };
    case CalendarCategory.FILED:
      return {
        attributes: {
          status: { eq: OfferingStatus.Filed },
          or: [
            { publicFilingDate: { gte: ago20y, lte: future1y } },
            { publicFilingDate: { eq: null } },
          ],
        },
      };
    case CalendarCategory.POSTPONED:
      return {
        attributes: {
          status: { in: [OfferingStatus.Postponed, OfferingStatus.Withdrawn] },
          or: [
            { publicFilingDate: { gte: ago20y, lte: future20y } },
            { publicFilingDate: { eq: null } },
          ],
          postponedDate: { gte: yearStart, lte: future20y },
        },
      };
    case CalendarCategory.LOCK_UP_EXPIRATION:
      return {
        attributes: {
          status: { eq: OfferingStatus.Priced },
          or: [{ pricingDate: { gte: ago20y, lte: future1y } }, { pricingDate: { eq: null } }],
          lockUpExpirationDate: { gte: lastSaturday, lte: future2w },
        },
      };
    case CalendarCategory.MY_OFFERINGS:
    case CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS:
      return {
        attributes: {
          or: [
            {
              status: { neq: OfferingStatus.Priced },
            },
            {
              status: { eq: OfferingStatus.Priced },
              or: [
                { firstTradeDate: { gte: ago2w, lte: future1y } },
                { firstTradeDate: { eq: null }, type: { neq: OfferingType.Convertible } },
              ],
            },
          ],
        },
        or: [
          { userOfferings: { some: { isFollowing: { eq: true } } } },
          { ioiNotes: { any: true } },
          { allocations: { any: true } },
          { indicationsOfInterest: { any: true } },
          { fundAllocations: { any: true } },
          { fundIndicationsOfInterest: { any: true } },
        ],
      };
    default:
      return undefined;
  }
}

const ONE_MILLION = 1000000;
export const getFiltersWhere = (filters: FilterValues): OfferingFilterInput => {
  const {
    sizeInDollars,
    marketCap,
    sector: sectorsField, // sectors
    customSectorId: customSectorsField, // customSectors customSectorsField,
    useCustomSectors,
    leftLeadFirmId: leftLeadIds, // leftleads
    managerFirmId: managerIds, // underwriters
    countries,
  } = filters;
  const showInternational = isInternationalOfferingsOn();

  const latestGrossProceedsTotalUsd =
    sizeInDollars?.min || sizeInDollars?.max
      ? {
          gte: sizeInDollars?.min ? sizeInDollars?.min * ONE_MILLION : undefined,
          lte: sizeInDollars?.max ? sizeInDollars?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const marketCapAtPricingUsd =
    marketCap?.min || marketCap?.max
      ? {
          gte: marketCap?.min ? marketCap?.min * ONE_MILLION : undefined,
          lte: marketCap?.max ? marketCap?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const leftLeadId = (leftLeadIds ?? []).length > 0 ? { in: leftLeadIds } : undefined;
  const exchangeCountry =
    (countries ?? []).length > 0 && showInternational ? { in: countries as Country[] } : undefined;

  const sectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SECTOR'))
    .map(s => s.split(':')[1] as Sector);
  const sector = sectors.length > 0 ? { sector: { in: sectors } } : undefined;
  const subSectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SUB_SECTOR'))
    .map(s => s.split(':')[1] as SubSector);
  const subSector = subSectors.length > 0 ? { subSector: { in: subSectors } } : undefined;
  const sectorOrSubSector =
    (sector || subSector) && !useCustomSectors
      ? { or: [sector, subSector].filter(s => !!s) as IssuerFilterInput[] }
      : undefined;

  const customSectors =
    (customSectorsField ?? []).length > 0 && useCustomSectors
      ? {
          or: Object.entries(
            customSectorsField!.reduce((result, value) => {
              const [type, id] = value.split(':');
              const key = type === 'SUB_SECTOR' ? 'customSubsectorId' : 'customSectorId';
              result[key] = result[key] ?? { in: [] };
              result[key]!.in!.push(id); // `!` required because of how CustomSectorIssuerFilterInput is typed
              return result;
            }, {} as CustomSectorIssuerFilterInput)
          ).map(([key, filter]) => ({ [key]: filter })),
        }
      : undefined;

  const sizeAndMarketCap =
    latestGrossProceedsTotalUsd || marketCapAtPricingUsd
      ? {
          or: [
            {
              latestGrossProceedsTotalUsd,
              marketCapAtPricingUsd,
            },
            // include null latestGrossProceedsTotalUsd and null marketCapAtPricingUsd
            ...(latestGrossProceedsTotalUsd
              ? [
                  {
                    latestGrossProceedsTotalUsd: { eq: null },
                    marketCapAtPricingUsd,
                  },
                ]
              : []),
            ...(marketCapAtPricingUsd
              ? [
                  {
                    latestGrossProceedsTotalUsd,
                    marketCapAtPricingUsd: { eq: null },
                  },
                ]
              : []),
            ...(latestGrossProceedsTotalUsd && marketCapAtPricingUsd
              ? [
                  {
                    latestGrossProceedsTotalUsd: { eq: null },
                    marketCapAtPricingUsd: { eq: null },
                    leftLeadId,
                    exchangeCountry,
                  },
                ]
              : []),
          ],
        }
      : undefined;
  return {
    attributes:
      sizeAndMarketCap || leftLeadId || exchangeCountry
        ? {
            ...sizeAndMarketCap,
            leftLeadId,
            exchangeCountry,
          }
        : undefined,
    issuer:
      sectorOrSubSector || customSectors
        ? {
            ...sectorOrSubSector,
            customSectors,
          }
        : undefined,
    managers:
      (managerIds ?? []).length > 0 ? { some: { manager: { id: { in: managerIds } } } } : undefined,
  };
};

export type OrderProps = { orderBy: string; orderByType: 'asc' | 'desc' | 'descWithNullFirst' };

const orderByTypeToDirection = {
  asc: SortEnumType.Asc,
  desc: SortEnumType.Desc,
  descWithNullFirst: SortEnumType.Desc, // default backend behaviour
};

// fields with computed `*NonNull` field for asc-null-first sort order
const nonNullFields = ['pricingDate', 'attributes.pricingDate'];

export function getGraphqlOrder(order: OrderProps, groupOrder: OrderProps) {
  return [
    getOfferingSort({
      orderBy:
        nonNullFields.includes(groupOrder.orderBy) && groupOrder.orderByType === 'asc'
          ? `${groupOrder.orderBy}NonNull`
          : groupOrder.orderBy,
      orderByType: groupOrder.orderByType,
    }),
    getOfferingSort(order),
  ];
}

export function getOfferingSort({ orderBy, orderByType }: OrderProps): OfferingSortInput {
  const direction = orderByTypeToDirection[orderByType];

  const orderFields = orderBy
    .split('.')
    .reverse()
    .reduce((sort, key, index) => {
      if (index === 0 && key.endsWith('DisplayName')) {
        return { [key.replace('DisplayName', '')]: { displayName: direction } };
      }
      return { [key]: index === 0 ? direction : sort };
    }, {});

  return orderFields;
}
