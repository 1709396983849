import {
  AsyncMultiSelectField,
  CheckboxSelectField,
  NumericRangeInputField,
  TreeSelectField,
} from '@cmg/common';
import {
  datalabMarketCapSelector,
  datalabRegionCountryFilterSelector,
  datalabSizeInDollarsSelector,
} from '@cmg/e2e-selectors';
import { Form } from 'formik';
import omit from 'lodash/omit';
import React from 'react';
import styled from 'styled-components/macro';

import { useFetchCustomSectors } from '../../dlgw/components/offerings-filter-form/hooks/useFetchCustomSectors';
import { useFetchRegionsAndCountries } from '../../dlgw/components/offerings-filter-form/hooks/useFetchRegionsAndCountries';
import { useFetchSectors } from '../../dlgw/components/offerings-filter-form/hooks/useFetchSectors';
import { useSearchLeftleads } from '../../dlgw/components/offerings-filter-form/hooks/useSearchLeftleads';
import { useSearchUnderwriters } from '../../dlgw/components/offerings-filter-form/hooks/useSearchUnderwriters';
import SectorToggleLabel from '../../shared/sectors/SectorToggleLabel';
import { FilterOptions } from '../ducks';

const SFormRow = styled.div`
  padding: 10px 20px 0px 20px;
  > * {
    width: 250px;
    margin-right: 15px;
  }
`;

export type Props = {
  showInternational: boolean;
  filterOptions: FilterOptions;
  useCustomSectors: boolean;
  handleSubmit: () => void;
  setFieldValue: (fieldName: string, value: any) => void;
};

const CalendarFilterForm: React.FC<Props> = ({
  showInternational,
  filterOptions,
  useCustomSectors,
  handleSubmit,
  setFieldValue,
}) => {
  // since we don't collect sub-sectors for intl deals, we don't want to show them in the filter
  const sectorOptions = useFetchSectors().map(sector => omit(sector, 'children'));
  const customSectorOptions = useFetchCustomSectors().map(sector => omit(sector, 'children'));

  const { defaultOptions: managerDefaultOptions, loadOptions: managerLoadOptions } =
    useSearchUnderwriters();
  const { defaultOptions: leftleadDefaultOptions, loadOptions: leftleadLoadOptions } =
    useSearchLeftleads();
  const { regionCountryOptions, getRegionCountries } = useFetchRegionsAndCountries();

  return (
    <Form>
      <SFormRow>
        <CheckboxSelectField
          testId="offeringType-select"
          name="offeringType"
          label="Offering Type"
          options={filterOptions.offeringType}
          resetValues={filterOptions.offeringType}
          withMargin
          width={250}
          onChange={() => handleSubmit()}
        />
        <NumericRangeInputField
          data-test-id={datalabSizeInDollarsSelector}
          name="sizeInDollars"
          label="Size ($M)"
          inputsProps={{ precision: 2 }}
          onChange={() => handleSubmit()}
        />
        <NumericRangeInputField
          data-test-id={datalabMarketCapSelector}
          name="marketCap"
          label="Market Cap ($M)"
          inputsProps={{ precision: 2 }}
          onChange={() => handleSubmit()}
        />
        {useCustomSectors ? (
          <TreeSelectField
            testId="CalendarFilterForm-CustomSectors"
            name="customSectorId"
            treeData={customSectorOptions}
            label={<SectorToggleLabel onChange={handleSubmit} />}
            withMargin
            onChange={() => handleSubmit()}
          />
        ) : (
          <TreeSelectField
            testId="CalendarFilterForm-Sectors"
            name="sector"
            treeData={sectorOptions}
            label={<SectorToggleLabel onChange={handleSubmit} />}
            withMargin
            onChange={() => handleSubmit()}
          />
        )}
        <AsyncMultiSelectField
          testId="leftLead-select"
          name="leftLeadFirmId"
          label="Left Lead"
          defaultOptions={leftleadDefaultOptions}
          loadOptions={leftleadLoadOptions}
          withMargin
          onChange={() => handleSubmit()}
        />
        <AsyncMultiSelectField
          testId="managerFirmId-select"
          name="managerFirmId"
          label="Underwriter"
          defaultOptions={managerDefaultOptions}
          loadOptions={managerLoadOptions}
          withMargin
          onChange={() => handleSubmit()}
        />
        {showInternational && (
          <TreeSelectField
            data-test-id={datalabRegionCountryFilterSelector}
            testId="countries-select"
            name="countries"
            treeData={regionCountryOptions}
            label="Region/Country"
            withMargin
            onChange={(countries: Parameters<typeof getRegionCountries>[0]) => {
              setFieldValue('countries', getRegionCountries(countries));
              handleSubmit();
            }}
          />
        )}
      </SFormRow>
    </Form>
  );
};

export default CalendarFilterForm;
