import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { IpoPricingChartsDto, IpoPricingChartsQuery } from './types/IpoPricingCharts';
import {
  IpoPricingDownloadQuery,
  IpoPricingTableDto,
  IpoPricingTableQuery,
} from './types/IpoPricngTable';

export const fetchIpoPricingCharts = makePostRequest<IpoPricingChartsQuery, IpoPricingChartsDto>(
  apiClient,
  () => '/dataLabs/ipoPricing/chart'
);
export type FetchIpoPricingChartsResponse = UnpackPromise<ReturnType<typeof fetchIpoPricingCharts>>;

export const fetchIpoPricingTable = makePostRequest<IpoPricingTableQuery, IpoPricingTableDto>(
  apiClient,
  () => '/dataLabs/ipoPricing/table'
);
export type FetchIpoPricingTableResponse = UnpackPromise<ReturnType<typeof fetchIpoPricingTable>>;

export const downloadIpoPricing = makePostDownloadRequest<IpoPricingDownloadQuery>(
  apiClient,
  () => '/dataLabs/ipoPricing/download'
);
export type DownloadIpoPricingResponse = UnpackPromise<ReturnType<typeof downloadIpoPricing>>;
