import { makeEntity } from '../../../utils/mocks';
import {
  HistoricalDealSizeMarketCapTrend,
  HistoricalOfferingVolume,
  IpoStructureChartsDto,
  PercentIpoWithSecondaryComponent,
} from '../types/IpoStructureCharts';

export const makeHistoricalDealSizeMarketCapTrend = makeEntity<HistoricalDealSizeMarketCapTrend>({
  label: 'Historical deal size market cap trend mock',
  values: {
    dealSizeMeanBg: 420727462,
    dealSizeMedianBg: 187500000,
    marketCapMeanBg: 3060279202,
    marketCapMedianBg: 1164622590,
  },
});

export const makeHistoricalOfferingVolume = makeEntity<HistoricalOfferingVolume>({
  label: 'Historical Offering Volume mock',
  values: {
    amount: 42,
    value: 666,
  },
});

export const makePercentIpoWithSecondaryComponent = makeEntity<PercentIpoWithSecondaryComponent>({
  count: 23,
  label: '< $100M',
  value: 42,
});

export const makeIpoStructureCharts = makeEntity<IpoStructureChartsDto>({
  daysFromConfidentialFilingToPublicFiling: [],
  historicalDealSizeMarketCapTrends: [],
  historicalOfferingVolume: [],
  offeringVolumeBySector: [],
  percentIposWithSecondaryComponent: [],
  percentSecondarySharesOffered: [],
  sizePrePostOfferingMarketCap: [],
});
