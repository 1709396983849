import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  BrokerAnalysisDownloadQuery,
  BrokerAnalysisTableDto,
  BrokerAnalysisTableQuery,
} from './types/IoiLeagueTable';

export const fetchIoiLeagueTable = makePostRequest<
  BrokerAnalysisTableQuery,
  BrokerAnalysisTableDto
>(apiClient, () => '/dataLabs/ioiLeagueTable/table');
export type FetchIoiLeagueTableResponse = UnpackPromise<ReturnType<typeof fetchIoiLeagueTable>>;

export const downloadIoiLeagueTable = makePostDownloadRequest<BrokerAnalysisDownloadQuery>(
  apiClient,
  () => '/dataLabs/ioiLeagueTable/download'
);
export type DownloadIoiLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadIoiLeagueTable>
>;
