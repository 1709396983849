import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  FundLevelAnalysisDownloadQuery,
  FundLevelAnalysisTableDto,
  FundLevelAnalysisTableQuery,
} from './types/FundIoiLeagueTable';

export const fetchFundIoiLeagueTable = makePostRequest<
  FundLevelAnalysisTableQuery,
  FundLevelAnalysisTableDto
>(apiClient, () => '/dataLabs/fundIoiLeagueTable/table');
export type FetchFundIoiLeagueTableResponse = UnpackPromise<
  ReturnType<typeof fetchFundIoiLeagueTable>
>;

export const downloadFundIoiLeagueTable = makePostDownloadRequest<FundLevelAnalysisDownloadQuery>(
  apiClient,
  () => '/dataLabs/fundIoiLeagueTable/download'
);
export type DownloadFundIoiLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadFundIoiLeagueTable>
>;
