import { makeModificationSummary } from '../../../core';
import { makeEntity } from '../../../utils';
import { CustomSubSectorDto } from '../types/CustomSubSector';
import { makeCustomSectorBase } from './CustomSectorBase.mock';

export const makeCustomSubSector = makeEntity<CustomSubSectorDto>({
  ...makeCustomSectorBase(),
  parentId: 'mock-sector-parent-id',
  lastModified: makeModificationSummary(),
  sectorId: 'mock-sector-id',
});
