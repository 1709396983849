import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { LeagueDownloadQuery, LeagueTableDto, LeagueTableQuery } from './types/LeagueTable';

export const fetchLeagueTable = makePostRequest<LeagueTableQuery, LeagueTableDto>(
  apiClient,
  () => '/dataLabs/ecmLeagueTable/table'
);
export type FetchLeagueTableResponse = UnpackPromise<ReturnType<typeof fetchLeagueTable>>;

export const downloadLeagueTable = makePostDownloadRequest<LeagueDownloadQuery>(
  apiClient,
  () => '/dataLabs/ecmLeagueTable/download'
);
export type DownloadLeagueTableResponse = UnpackPromise<ReturnType<typeof downloadLeagueTable>>;
