import { makeEntity } from '../../../utils/mocks';
import {
  makeAfterMarketRelativeToPricing,
  makeAverage,
  makeOfferToHeatMap,
  makePricingVsInitialRange,
  makeTurnoverHeatMap,
  makeValueBySector,
} from '../../mocks';
import { IpoPricingChartsDto } from '../types/IpoPricingCharts';

export const makeIpoPricingChartsDto = makeEntity<IpoPricingChartsDto>({
  afterMarketRelativeToPricing: {
    performance: makeAfterMarketRelativeToPricing(),
  },
  firstDayTurnover: [makeValueBySector()],
  percentOfIposTradingAboveOffer: [makeValueBySector()],
  offerToMidpointPercentChange: [makeAverage()],
  performanceHeatMapBySector: makeOfferToHeatMap(),
  volumeDropoffBySector: makeTurnoverHeatMap(),
  pricingVsInitialRange: [makePricingVsInitialRange()],
});
