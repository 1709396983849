import { makeEntity } from '../../../utils/mocks';
import { CapitalRaisedMatrixReport, UsEcmMatrixChartsDto } from '../types/UsEcmMatrixCharts';

export const makeCapitalRaisedMatrixReport = makeEntity<CapitalRaisedMatrixReport>({
  label: 'Mock report',
  rows: [],
  summaries: [],
});

export const makeUsEcmMatrixCharts = makeEntity<UsEcmMatrixChartsDto>({
  weekly: [makeCapitalRaisedMatrixReport({ label: 'Weekly mock report' })],
  monthly: [makeCapitalRaisedMatrixReport({ label: 'Monthly mock report' })],
  quarterly: [makeCapitalRaisedMatrixReport({ label: 'Quarterly mock report' })],
});
