import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  IpoBacklogDownloadQuery,
  IpoBacklogTableDto,
  IpoBacklogTableQuery,
} from './types/IpoBacklogTable';

export const fetchIpoBacklogTable = makePostRequest<IpoBacklogTableQuery, IpoBacklogTableDto>(
  apiClient,
  () => '/dataLabs/ipoBacklog/table'
);
export type FetchIpoBacklogTableResponse = UnpackPromise<ReturnType<typeof fetchIpoBacklogTable>>;

export const downloadIpoBacklog = makePostDownloadRequest<IpoBacklogDownloadQuery>(
  apiClient,
  () => '/dataLabs/ipoBacklog/download'
);
export type DownloadIpoBacklogResponse = UnpackPromise<ReturnType<typeof downloadIpoBacklog>>;
