import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  SponsorLeagueDownloadQuery,
  SponsorLeagueTableDto,
  SponsorLeagueTableQuery,
} from './types/SponsorLeagueTable';

export const fetchSponsorLeagueTable = makePostRequest<
  SponsorLeagueTableQuery,
  SponsorLeagueTableDto
>(apiClient, () => '/dataLabs/sponsorLeagueTable/table');
export type FetchSponsorLeagueTableResponse = UnpackPromise<
  ReturnType<typeof fetchSponsorLeagueTable>
>;

export const downloadSponsorLeagueTable = makePostDownloadRequest<SponsorLeagueDownloadQuery>(
  apiClient,
  () => '/dataLabs/sponsorLeagueTable/download'
);
export type DownloadSponsorLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadSponsorLeagueTable>
>;
