import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  LockUpExpirationDownloadQuery,
  LockUpExpirationTableDto,
  LockUpExpirationTableQuery,
} from './types/LockUpExpirationTable';

export const fetchLockUpExpirationTable = makePostRequest<
  LockUpExpirationTableQuery,
  LockUpExpirationTableDto
>(apiClient, () => '/dataLabs/lockUpExpiration/table');
export type FetchLockUpExpirationTableResponse = UnpackPromise<
  ReturnType<typeof fetchLockUpExpirationTable>
>;

export const downloadLockUpExpiration = makePostDownloadRequest<LockUpExpirationDownloadQuery>(
  apiClient,
  () => '/dataLabs/lockUpExpiration/download'
);
export type DownloadLockUpExpirationResponse = UnpackPromise<
  ReturnType<typeof downloadLockUpExpiration>
>;
