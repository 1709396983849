import { makeEntity } from '../../../utils/mocks';
import {
  makeFollowOnSummary,
  makeIpoSummary,
  makeOfferingByTypeInfo,
  makeOfferToHeatMap,
} from '../../mocks';
import { ParticipationSummaryChartsDto } from '../types/IoiParticipationCharts';

export const makeParticipationSummaryChartsDto = makeEntity<ParticipationSummaryChartsDto>({
  offerings: 666,
  investedDollars: 42000000,
  ipo: makeIpoSummary(),
  followOn: makeFollowOnSummary(),
  offeringByType: [],
  offeringByTypeInfo: makeOfferingByTypeInfo(),
  participationVolumeBySector: [],
  participationVolumeByType: [],
  historicalParticipationVolume: [],
  percentOfOfferingsTradingAboveOffer: [],
  firstDayTurnover: [],
  afterMarketRelativeToIoi: {
    performance: {},
  },
  performanceHeatMapBySector: makeOfferToHeatMap(),
  weightedPerformanceHeatMapBySector: makeOfferToHeatMap(),
});
