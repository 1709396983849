import { makeEntity } from '../../../utils/mocks';
import { makeOfferToHeatMap } from '../../mocks';
import { UsEcmChartsDto } from '../types/UsEcmCharts';

export const makeUsEcmChartsDto = makeEntity<UsEcmChartsDto>({
  capitalRaised: 42000000,
  offerings: 23,
  firstDayTurnover: [],
  offeringVolumeBySector: [],
  percentOfOfferingsTradingAboveOffer: [],
  performanceHeatMapBySector: makeOfferToHeatMap(),
  performanceHeatMapByOfferingType: makeOfferToHeatMap(),
  ipo: {
    above: 12,
    below: 4,
    current: 10,
    day1: 1234,
    day30: 3245,
    within: 122,
  },
  followOn: {
    current: 10,
    day1: 2,
    day30: 42,
    fileToOffer: 100,
    toLastTrade: 134,
  },
  historicalOfferingVolume: [],
  offeringByType: [],
  offeringVolumeByType: [],
  offeringByTypeInfo: {
    followOn: {
      price: 1234,
      quantity: 42,
    },
    ipo: {
      price: 355,
      quantity: 24,
    },
  },
});
