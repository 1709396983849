import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  UnderwritingTermsChartsQuery,
  UnderwritingTermsDashboardDto,
} from './types/UnderwritingStructureCharts';
import {
  UnderwritingTermsDownloadQuery,
  UnderwritingTermsTableDto,
  UnderwritingTermsTableQuery,
} from './types/UnderwritingStructureTable';

export const fetchUnderwritingStructureCharts = makePostRequest<
  UnderwritingTermsChartsQuery,
  UnderwritingTermsDashboardDto
>(apiClient, () => '/dataLabs/underwritingStructure/chart');
export type FetchUnderwritingStructureChartsResponse = UnpackPromise<
  ReturnType<typeof fetchUnderwritingStructureCharts>
>;

export const fetchUnderwritingStructureTable = makePostRequest<
  UnderwritingTermsTableQuery,
  UnderwritingTermsTableDto
>(apiClient, () => '/dataLabs/underwritingStructure/table');
export type FetchUnderwritingStructureTableResponse = UnpackPromise<
  ReturnType<typeof fetchUnderwritingStructureTable>
>;

export const downloadUnderwritingStructure =
  makePostDownloadRequest<UnderwritingTermsDownloadQuery>(
    apiClient,
    () => '/dataLabs/underwritingStructure/download'
  );
export type DownloadUnderwritingStructureResponse = UnpackPromise<
  ReturnType<typeof downloadUnderwritingStructure>
>;
